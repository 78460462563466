import React from "react"
import get from "lodash/get"
import Helmet from "react-helmet"
import {graphql} from "gatsby"

import LocalizedLink from "../../components/LocalizedLink"
import Layout from "../../components/layout"

class About extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title")

    return (
      <Layout location={this.props.location}>
        <Helmet title={siteTitle}>
          <meta
            name="description"
            content="A Game Studio est l'aboutissement d'un travail de recherche, apprentissage, conception et développement, nécessaire pour transformer une passion créatrice en production professionelle de jeux vidéos."
          />
        </Helmet>
        <h1>A propos de A Game Studio</h1>
        <h2>Histoire</h2>
        <p>
          Créateur de jeux vidéos depuis 2010, la naissance de A Game Studio en
          2018 est l'aboutissement d'un travail de recherche, apprentissage,
          conception et développement, nécessaire pour transformer une passion
          créatrice en production professionelle de jeux vidéo.
        </p>
        <p>
          Le premier jeu de A Game Studio – «{" "}
          <LocalizedLink to="/projects/a-time-paradox">
            A Time Paradox
          </LocalizedLink>{" "}
          » – est prévu pour octobre 2018 sur PC et Android.
        </p>
        <h2>Nous contacter</h2>
        <ul>
          <li>
            Par email : 
            <a href="mailto:contact@a-game-studio.com">
              contact@a-game-studio.com
            </a>
          </li>
          <li>
            Sur twitter :
            <a href="https://twitter.com/agamestudio_">@agamestudio_</a>
          </li>
          <li>
            IRL: au{" "}
            <a href="https://www.meetup.com/fr-FR/Game-Dev-Afterwork-Nantes/">
              Game Dev Afterwork de Nantes
            </a>
          </li>
        </ul>
        <h2>L'équipe</h2>
        <ul>
          <li>Lythom (Samuel Bouchet) — Fondateur et Artisan vidéoludique</li>
        </ul>
        <h3>Avec l'aide de</h3>
        <ul>
          <li>Agathe Robache – Écriture</li>
          <li>Jaqueline Florencio – Illustration, Concept Art</li>
          <li>Magali Ploteau – Conception, Level Design, Tests</li>
          <li>Marie Bouchet – Character design</li>
          <li>Philippe Salib (Lonestone studio) – Pixel art</li>
        </ul>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query AboutQueryFR {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default About
